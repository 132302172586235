import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import _objectSpread from "/root/workspace/trade-markert-v2_5BPH/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import UserApi from '@/api/UserApi';
import { mapGetters } from "vuex";
export default {
  name: 'modify_password',
  props: {
    width: {
      type: String,
      default: '35vw'
    }
  },
  data: function data() {
    var checkPassword = function checkPassword(rule, value, callback) {
      var hasUpperCase = /[A-Z]/.test(value);
      var hasLowerCase = /[a-z]/.test(value);
      var hasNumber = /\d/.test(value);
      var hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);

      if (hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar) {
        callback();
      } else {
        callback(new Error('密码必须包含大写字母、小写字母、数字和特殊符号'));
      }
    };

    return {
      title: '修改密码',
      isShowVisible: true,
      rules: {
        oldPassword: [{
          required: true,
          message: '请填写旧密码',
          tirgger: 'blur'
        }],
        password: [// {required: true, message: '请填写新密码', tirgger: 'blur'},
        // {min: 6, message: "密码长度最少为6位", trigger: "blur"},
        {
          min: 8,
          message: "密码长度最少为8位",
          trigger: "blur"
        }, {
          max: 16,
          message: "密码长度最多为16位",
          trigger: "blur"
        }, {
          required: true,
          validator: checkPassword,
          trigger: "blur"
        }],
        secondPassword: [{
          required: true,
          message: '请确认密码',
          tirgger: 'blur'
        } // { min: 6, message: "密码长度最少为6位", trigger: "blur" },
        ]
      },
      passwordData: {
        accountId: ''
      }
    };
  },
  computed: _objectSpread({}, mapGetters(["userInfo"])),
  created: function created() {
    this.passwordData.accountId = this.userInfo.id;
  },
  methods: {
    savePassword: function savePassword() {
      var _this = this;

      this.$refs['passwordData'].validate(function (valid, object) {
        if (valid) {
          if (_this.passwordData.password === _this.passwordData.oldPassword) {
            _this.$message.error('新老密码不能一致!');
          } else {
            if (_this.passwordData.password === _this.passwordData.secondPassword) {
              var loading = _this.$loading({
                lock: true,
                text: '请耐心等待.....',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
              });

              var http = new UserApi();
              http.updatePassword(_this.passwordData).then(function (res) {
                loading.close();

                if (res.code === 200) {
                  _this.$message.success('修改成功!');

                  _this.$emit('close');
                } else {
                  _this.$message.error('修改失败!');
                }
              }).catch(function (e) {
                loading.close();
              });
            } else {
              _this.$message.error('两次输入的新密码必须一致!');
            }
          }
        } else {
          var validArr = [];
          Object.keys(object).forEach(function (key) {
            validArr.push(key);
          });
          var validVal = "#" + validArr[0];
          document.querySelector(validVal).scrollIntoView(true);
        }
      });
    }
  }
};